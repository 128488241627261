import { mapGetters } from 'vuex';
import bffApplicationServices from '../../services/bff/bffApplicationServices';
import bffUserServices from '../../services/bff/bffUserServices';
import store from '@/store/store';

export default {
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'fullName',
          width: '20%'
        },
        { text: 'CommonId', value: 'commonId', width: '7%' },
        { text: 'Email Address', value: 'email', width: '20%' },
        { text: 'Team', value: 'teamName', width: '15%' },
        { text: '', width: '3%', value: 'blank' }
      ],
      application: {},
      isLoading: true,
      showDeletePermissionDialogBox: false,
      selectedUser: null,
      currentUsers: [],
      searchedUsers: [],
      isProcessing: false,
      autoCompleteUsers: [],
      autoCompleteSelect: '',
      autoCompleteSearch: '',
      autoCompleteLoading: false
    };
  },
  watch: {
    $route: { handler: 'loadUsers', immediate: true },
    autoCompleteSearch: async function(name) {
      if (name && name.length > 2) {
        this.autoCompleteLoading = true;
        await this.populateAutoCompleteUsersByName(name);
        this.autoCompleteLoading = false;
      }
    }
  },
  methods: {
    async loadUsers() {
      this.isLoading = true;

      await this.loadCurrentApplication();

      await store.dispatch('user/getApplicationPermissions', this.application.id);
      const appPermissions = this.$store.state.user.applicationPermissions;

      const commonIds = appPermissions.map((permission) => permission.userId);

      if (commonIds.length === 0) {
        this.currentUsers = [];
        this.isLoading = false;
        return;
      }

      const uniqueCommonIds = [...new Set(commonIds)];

      const response = await bffUserServices.getUsersByCommonIds(uniqueCommonIds);

      this.currentUsers = response.data.data;

      this.isLoading = false;
    },
    async loadCurrentApplication() {
      await store.dispatch('applications/getApplication', { userId: this.user.commonid, applicationId: this.$route.params.id });
      this.application = this.$store.state.applications.currentApplication;
    },
    async populateAutoCompleteUsersByName(teamMemberName) {
      this.autoCompleteUsers = [];

      const response = await bffUserServices.getUsersByName(teamMemberName);

      this.searchedUsers = response.data.data;

      for (let i = 0; i < this.searchedUsers.length; i++) {
        const user = this.searchedUsers[i];

        let autoCompleteUser = {
          text: user.fullName,
          value: user.commonId
        };

        this.autoCompleteUsers.push(autoCompleteUser);
      }
    },
    async addUserPermission() {
      const response = await bffApplicationServices.createUserPermission({
        appId: this.application.id,
        userId: this.autoCompleteSelect
      });

      if (!response) {
        this.$snotify.error('Failed to add user permission');
        return;
      }

      const searchedUser = this.searchedUsers.find((user) => user.commonId === this.autoCompleteSelect);

      const searchedUserIndex = this.currentUsers.findIndex((user) => user.commonId === searchedUser.commonId);
      if (searchedUserIndex === -1) {
        this.currentUsers.push(searchedUser);
      }

      this.$snotify.success('Successfully added user permission');
    },
    openDeletePermissionDialogBox(user) {
      this.showDeletePermissionDialogBox = true;
      this.selectedUser = user;
    },
    async deleteUserPermission() {
      this.showDeletePermissionDialogBox = false;

      const response = await bffApplicationServices.deleteUserPermission({
        appId: this.application.id,
        userId: this.selectedUser.commonId
      });

      if (!response) {
        this.$snotify.error('Failed to delete user permission');
        return;
      }

      const searchedTMIndex = this.currentUsers.findIndex((user) => user.commonId === this.selectedUser.commonId);
      this.currentUsers.splice(searchedTMIndex, 1);

      this.$snotify.success('Successfully deleted user permission');
    }
  },
  computed: {
    ...mapGetters({
      user: ['auth/user']
    })
  }
};
